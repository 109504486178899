import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useAccount } from "wagmi";
import { Button } from "@/src/components";
import { ConnectButton } from "@/src/components/connectButton";
import { useBalances } from "@/src/hooks/useBalances";
import { useMetrics } from "@/src/hooks/useMetrics";
import { useStake } from "@/src/hooks/useStake";
import { toast } from "react-hot-toast";
import { activeChain } from "@/src/configs/chainConfig";

export const RedeemRewards = () => {
  const { chain } = useAccount();
  const {  onMintReward, onMintRewardPendingRewards } = useStake();
  const { address, isConnected } = useAccount();
  const { frbBalance, stakeBalance, rewardPoints, rewardRequirement, rewardReborn, bronzeSupply, pendingRewards } = useBalances();
  const { nftSupply } = useMetrics();
  const [isValid, setValid] = useState(false);

  const validate = () => {
    if(rewardPoints < rewardRequirement){
      return true;
    }
    if(frbBalance < rewardReborn){
      return true;
    }
      return false;
  };
  useEffect(() => {
    const isValid = validate();
    setValid(isValid);
  }, [frbBalance, rewardPoints, rewardReborn, rewardReborn, nftSupply]);


  const handleSubmit = async (event) => {
    if (!isConnected) return;
    event.preventDefault();
      toast.promise(onMintReward(), {
        loading: "Transaction is in progress",
        success: "Minted Flare Reborn NFT",
        error: (data) => data,
      });
  };
  const mintPending = async (event) => {
    if (!isConnected) return;
    event.preventDefault();
      toast.promise(onMintRewardPendingRewards(), {
        loading: "Transaction is in progress",
        success: "Minted Flare Reborn NFT",
        error: (data) => data,
      });
  };
  const [{ isCorrectChain }, setState] = useState({
    isCorrectChain: true,
  });

  useEffect(() => {
    if (isConnected)
      setState({
        isCorrectChain: chain?.id === Number(activeChain.chainId),
      });
    
  }, [chain, isConnected]);
  return (    
    <div className="md:border-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8">
      <div className= "py-2 flex justify-center">
       <h2 className="text-foreground text-2xl font-medium flex gap-2 py-1">
                 Reborn NFT
      </h2>
      </div>
       <div className="flex justify-center">
       <img src={process.env.PUBLIC_URL + 'CRF_KEY.gif'} width="350" heigh="350" style={{ alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }} />
       </div>
      <form onSubmit={handleSubmit}>
      <div className="flex justify-center py-2 text-foreground/50">
           <p><b>Supply:</b> {parseFloat(nftSupply).toFixed(0)}/2000</p>
        </div>
        <div className="flex justify-center py-2 text-foreground/50">
           <p><b>Harvest Points:</b> {parseFloat(rewardPoints).toFixed(2)}/{parseFloat(rewardRequirement).toFixed(2)}</p>
        </div>
        <div className="flex justify-center py-2 text-foreground/50">
           <p><b>FRB Required:</b> {parseFloat(frbBalance).toFixed(2)}/{parseFloat(rewardReborn).toFixed(2)}</p>
        </div>
        <div className="border-t-2 border-foreground/30" />
        <div className="mt-10">
          <Button type="submit" disabled={!isCorrectChain || parseInt(rewardPoints) < parseInt(rewardRequirement) || rewardRequirement==0 || parseInt(frbBalance) < parseInt(rewardReborn)} ><p>Mint Flare Reborn NFT</p></Button>
        </div>
        
      </form>
      <div className="mt-10">
          <Button type="submit" onClick={mintPending} disabled={!isCorrectChain || parseInt(rewardPoints) < parseInt(rewardRequirement) || rewardRequirement==0 || parseInt(pendingRewards) < parseInt(rewardReborn)} ><p>Mint NFT with pending rewards</p></Button>
        </div>
    </div>
  );
};
