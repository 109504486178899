import { Query } from "@/src/icons";
import { Card, Tooltip } from "@/src/components";
import { useRebase } from "@/src/context";
import { Timer } from "@/src/components/Timer";
import { useAccount } from "wagmi";
import { useState, useEffect } from "react";
import { useBalances } from "@/src/hooks/useBalances";


export const HarvestInfo = () => {
  const { rebaseInterval, lastRebaseTime,} = useRebase();

  
  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
        <span className="flex items-center justify-between">
          <span className="flex gap-2">
            <h2 className="text-2xl font-semibold text-foreground">
              Harvest Info
            </h2>
          </span>

        </span>
        <p className="text-2xl md:text-3xl font-semibold text-foreground">
        {(parseInt(rebaseInterval)+parseInt(lastRebaseTime)) > 0 ? <Timer time={(parseInt(rebaseInterval)+parseInt(lastRebaseTime))}/> : "00:00:00"}
        </p>
        <h2 className="text-xl font-semibold text-foreground/50">
          Time until next Harvest
        </h2>
      </div>
    </Card>
  );
};
