import { BankIcon, Query } from "@/src/icons";
import { Card, Button } from "@/src/components";
import { useBalances } from "@/src/hooks/useBalances";
import { useStake } from "@/src/hooks/useStake";
import { useAccount } from "wagmi";
import { toast } from "react-hot-toast";
import { useVotes } from "@/src/hooks/useVotes";
import { useEffect, useRef, useState } from "react";

export const VoteButtons = () => {
  const { yes, no, vote_enabled, deadline } = useVotes();
  const { uncharged,charged } = useBalances();
  const { onClaimPendingRewards, onProposeSupply, onIncreaseSupply, onCancelVote } = useStake();
  const { isConnected } = useAccount();

  const [{ stakeInputReborn }, setInput] = useState({
    stakeInput: "",
    unstakeInput: "",
    stakeInputReborn:"",
  });
  const onInputChangeReborn = (event) => {
    const value = event.target.valueAsNumber;
    const _stakeInput = value;
    setInput({
      stakeInputReborn: _stakeInput 
    });
  };

  const onSupply = async () => {
    if (!isConnected) return;

    toast.promise(onProposeSupply(stakeInputReborn), {
      loading: "Transaction is in progress",
      success: "Successfully proposed supply increase ",
      error: (data) => data,
    });
  }
  const onIncrease = async () => {
    if (!isConnected) return;

    toast.promise(onIncreaseSupply(), {
      loading: "Transaction is in progress",
      success: "Successfully proposed supply increase ",
      error: (data) => data,
    });
  }
  const onCancel = async () => {
    if (!isConnected) return;

    toast.promise(onCancelVote(), {
      loading: "Transaction is in progress",
      success: "Successfully proposed supply increase ",
      error: (data) => data,
    });
  }

  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
        <div className="flex items-center h-full">
            <input
              value={stakeInputReborn}
              max={parseInt(2000000)}
              onChange={onInputChangeReborn}
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />
        </div>
        <div className="flex items-center h-full">
        <Button onClick={onSupply} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 }>
          <h2 className="md:text-xl text-xl text-foreground">Propose Supply Increase</h2>
        </Button>
        {}
      </div>

      <div className="flex items-center h-full">
        <Button onClick={onIncrease} className="" disabled={parseInt(no) >= parseInt(yes) || vote_enabled==false || (parseInt(uncharged)+parseInt(charged)) == 0 || Date.now()/1000 < (deadline)}>
          <h2 className="md:text-xl text-xl text-foreground">Increase Supply</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
        <Button onClick={onCancel} className="" disabled={parseInt(yes) > parseInt(no) ||vote_enabled==false ||(parseInt(uncharged)+parseInt(charged)) == 0 || Date.now()/1000 < (parseInt(deadline))}>
          <h2 className="md:text-xl text-xl text-foreground">Cancel Vote</h2>
        </Button>
        {}
      </div>
      </div>
    </Card>
    
  );
};
