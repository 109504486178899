import { createContext, useEffect, useState } from "react";
import { multicall } from '@wagmi/core'
import { formatEther, formatUnits } from "viem";
import { useAccount, usePublicClient } from "wagmi";
import {
  DAO_CONTRACT_ADDRESS,
  ERC20_ABI,
  ERC20_CONTRACT_ADDRESS,
  LP_CONTRACT_ADDRESS,
  NFT_ABI,
  NFT_CONTRACT_ADDRESS,
  VOTE_ABI,
  VOTE_ADDRESS,
  DAO_VOTE_ABI,
  DAO_VOTE_ADDRESS,
  REBORN_DAO_ABI,
  DAO_REBORN_ADDRESS,
} from "../lib/constants";

// initial state
const defaultValues = {
  deadline: 0,
  yes:0,
  no:0,
  eligibleVotes:0,
  nftSupply:0,
  functionCalled:0,
  vote_enabled:false,
  var1:0,
  var2:0,
  var3:0,
  getVotes: () => {},
  voteAmount:0,
};
// context
export const DaoVoteContext = createContext(defaultValues);

export const DaoVoteProvider = ({ children }) => {
  const publicClient = usePublicClient();
  const { address, isConnected } = useAccount();

  const [state, setVotes] = useState({
    deadline: 0,
    yes:0,
    no:0,
    eligibleVotes:0,
    functionCalled:0,
    vote_enabled:false,
    nftSupply:0,
    var1:0,
    var2:0,
    var3:0,
    voteAmount:0,
  });
 
  const getDaoVotes = async (address) => {
    //try {
      const [deadline, yes, no, eligibleVotes, vote_enabled, nftSupply, functionCalled, var1, var2, var3, voteAmount] = await Promise.all([
        publicClient.readContract({
          abi: DAO_VOTE_ABI,
          address: DAO_VOTE_ADDRESS,
          functionName: "deadline",
          args: [],
        }),
        publicClient.readContract({
          abi: DAO_VOTE_ABI,
          address: DAO_VOTE_ADDRESS,
          functionName: "votedYes",
          args: [],
        }),
        publicClient.readContract({
          abi: DAO_VOTE_ABI,
          address: DAO_VOTE_ADDRESS,
          functionName: "votedNo",
          args: [],
        }),
        publicClient.readContract({
          abi: DAO_VOTE_ABI,
          address: DAO_VOTE_ADDRESS,
          functionName: "getVotes",
          args: [address],
        }),
        publicClient.readContract({
          abi: DAO_VOTE_ABI,
          address: DAO_VOTE_ADDRESS,
          functionName: "vote_enabled",
          args: [],
        }),
        publicClient.readContract({
          abi: NFT_ABI,
          address: NFT_CONTRACT_ADDRESS,
          functionName: "totalSupply",
          args: [],
        }),
        publicClient.readContract({
          abi: DAO_VOTE_ABI,
          address: DAO_VOTE_ADDRESS,
          functionName: "functionCalled",
          args: [],
        }),
        publicClient.readContract({
          abi: DAO_VOTE_ABI,
          address: DAO_VOTE_ADDRESS,
          functionName: "var1",
          args: [],
        }),
        publicClient.readContract({
          abi: DAO_VOTE_ABI,
          address: DAO_VOTE_ADDRESS,
          functionName: "var2",
          args: [],
        }),
        publicClient.readContract({
          abi: DAO_VOTE_ABI,
          address: DAO_VOTE_ADDRESS,
          functionName: "var3",
          args: [],
        }),
        publicClient.readContract({
          abi: REBORN_DAO_ABI,
          address: DAO_REBORN_ADDRESS,
          functionName: "voteAmount",
          args: [],
        }),
      ]);
      setVotes({
        deadline: deadline || "0",
        yes: yes || "0",
        no: no || "0",
        eligibleVotes: eligibleVotes || "0",
        vote_enabled: vote_enabled || false,
        nftSupply: nftSupply || "0",
        functionCalled:functionCalled,
        var1: var1 || "0",
        var2: var2 || "0",
        var3: var3 || "0",
        voteAmount:formatEther(voteAmount || "0"),
      });


    //} catch (err) {}
    
  };

  useEffect(() => {
    isConnected && getDaoVotes(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [address, isConnected]);

  return (
    <DaoVoteContext.Provider value={{ ...state, getDaoVotes }}>
      {children}
    </DaoVoteContext.Provider>
  );
};
