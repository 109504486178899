import { BankIcon, Query } from "@/src/icons";
import { Card, Tooltip, Button } from "@/src/components";
import { useBalances } from "@/src/hooks/useBalances";
import { Link, NavLink } from "@/src/components";
import { useStake } from "@/src/hooks/useStake";
import { ReloadIcon } from "@/src/icons";
import { toast } from "react-hot-toast";
import { useAccount } from "wagmi";

export const Boosts = () => {
  const { nftBalance, nftBoost, lpBalance, lpBoost, lpMinimum, charged, unCharged } = useBalances();
  const { isConnected } = useAccount();
  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
        <span className="flex items-center justify-between">
          <span className="flex items-center gap-2">
            
            <h2 className="text-2xl text-foreground font-semibold">Harvest Boosters</h2>
          </span>
        </span>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
            {parseFloat(nftBoost/10000).toFixed(2)}% 
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">
            NFT Boost
          </h2>
         {unCharged > 0 && 
         <h2 className="text-xl font- text-foreground/50" >
         * You have uncharged NFTs
       </h2>} 
        </div>
      </div>
    </Card>
  );
};
