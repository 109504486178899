import { Card } from "@/src/components";
import { useVotes } from "@/src/hooks/useVotes";
import { Timer } from "@/src/components/deadlineTimer";

export const VoteBalance = () => {
  const { yes,no,deadline } = useVotes();
  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
        <span className="flex items-center justify-between">
          <span className="flex items-center gap-2">
            
            <h2 className="text-2xl text-foreground font-semibold">Vote Results</h2>
          </span>

          <div>
            
          </div>
        </span>

        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
            {parseFloat(yes).toFixed(0)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">YES votes</h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(no).toFixed(0)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">
            NO votes
          </h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseInt(deadline) > 0 ? <Timer time={parseInt(deadline)}/> : "00:00:00:00"}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Deadline</h2>
        </div>
      </div>
    </Card>
    
  );
};
