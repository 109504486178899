import { UserLayout } from "@/src/layouts/UserLayout";
import { Card } from "@/src/components";
import { PendingAirdrop, RedeemRewards, RewardBalance} from "@/src/views/home";
import { FileAdd } from "@/src/icons";
import { RebaseProvider } from "@/src/context";

export const Rewards = () => {
  return (
    <UserLayout>
      <div className="grid grid-cols-12 gap-4 max-w-[98rem] w-full h-full m-auto p-5">
        <div className="col-span-12 md:col-span-8">
          <Card className="md:min-h-[calc(100vh-130px)] h-full">
            <div className="grid gap-3 mb-10 md:mb-24">
              <h2 className="text-foreground text-2xl font-medium flex gap-2">
                 Harvest Rewards
              </h2>
              <p className="text-foreground"></p>
            </div>
           <div className="py-5"></div>
           <RedeemRewards />
          </Card>
        </div>
          <div className="col-span-12 md:col-span-4 h-full flex flex-col gap-4">
         {/*} <FarmBalance />
          <XenosFarm />*/}
          <RewardBalance />
          <PendingAirdrop />
          </div>
      </div>
    </UserLayout>
  );
};
