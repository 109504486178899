import { createContext, useEffect, useState } from "react";
import { formatEther, formatUnits } from "viem";
import { useAccount, usePublicClient } from "wagmi";
import {
  DAO_CONTRACT_ADDRESS,
  ERC20_ABI,
  ERC20_CONTRACT_ADDRESS,
  LP_CONTRACT_ADDRESS,
  NFT_ABI,
  NFT_CONTRACT_ADDRESS,
  VOTE_ABI,
  VOTE_ADDRESS,
  REBORN_DAO_ABI,
  DAO_REBORN_ADDRESS,
} from "../lib/constants";

// initial state
const defaultValues = {
  deadline: 0,
  yes:0,
  no:0,
  eligibleVotes:0,
  nftSupply:0,
  supplyAmount:0,
  vote_enabled:false,
  getVotes: () => {},
};
// context
export const VoteContext = createContext(defaultValues);

export const VoteProvider = ({ children }) => {
  const publicClient = usePublicClient();
  const { address, isConnected } = useAccount();

  const [state, setVotes] = useState({
    deadline: 0,
    yes:0,
    no:0,
    eligibleVotes:0,
    supplyAmount:0,
    vote_enabled:false,
    nftSupply:0,
  });
 
  const getVotes = async (address) => {
    //try {
      const [deadline, yes, no, eligibleVotes, vote_enabled, nftSupply, supplyAmount] = await Promise.all([
        publicClient.readContract({
          abi: VOTE_ABI,
          address: VOTE_ADDRESS,
          functionName: "deadline",
          args: [],
        }),
        publicClient.readContract({
          abi: VOTE_ABI,
          address: VOTE_ADDRESS,
          functionName: "votedYes",
          args: [],
        }),
        publicClient.readContract({
          abi: VOTE_ABI,
          address: VOTE_ADDRESS,
          functionName: "votedNo",
          args: [],
        }),
        publicClient.readContract({
          abi: VOTE_ABI,
          address: VOTE_ADDRESS,
          functionName: "getVotes",
          args: [address],
        }),
        publicClient.readContract({
          abi: VOTE_ABI,
          address: VOTE_ADDRESS,
          functionName: "vote_enabled",
          args: [],
        }),
        publicClient.readContract({
          abi: NFT_ABI,
          address: NFT_CONTRACT_ADDRESS,
          functionName: "totalSupply",
          args: [],
        }),
        publicClient.readContract({
          abi: VOTE_ABI,
          address: VOTE_ADDRESS,
          functionName: "supplyAmount",
          args: [],
        }),
      ]);
      setVotes({
        deadline: deadline || "0",
        yes: yes || "0",
        no: no || "0",
        eligibleVotes: eligibleVotes || "0",
        vote_enabled: vote_enabled || false,
        nftSupply: nftSupply || "0",
        supplyAmount:formatEther(supplyAmount || "0"),
      });


    //} catch (err) {}
    
  };

  useEffect(() => {
    isConnected && getVotes(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [address, isConnected]);

  return (
    <VoteContext.Provider value={{ ...state, getVotes }}>
      {children}
    </VoteContext.Provider>
  );
};
