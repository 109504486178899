import { createContext, useEffect, useState } from "react";
import { formatEther, formatUnits } from "viem";
import { useAccount, usePublicClient } from "wagmi";
import {
  DAO_CONTRACT_ADDRESS,
  ERC20_ABI,
  ERC20_CONTRACT_ADDRESS,
  LP_CONTRACT_ADDRESS,
  LP2_CONTRACT_ADDRESS,
  AIRDROP_ADDRESS,
  AIRDROP_ABI,
} from "../lib/constants";

// initial state
const defaultValues = {
  airdropAmount: 0,
  nextClaim:0,
  totalAmount:0,
  withdrawn:0,
  getAirdrop: () => {},
};
// context
export const AirdropContext = createContext(defaultValues);

export const AirdropProvider = ({ children }) => {
  const publicClient = usePublicClient();
  const { address, isConnected } = useAccount();

  const [state, setAirdrop] = useState({
    airdropAmount: 0,
    nextClaim:0,
    totalAmount:0,
    withdrawn:0,
  });
 
  const getAirdrop = async (address) => {
    //try {
      const [airdropAmount, nextClaim, totalAmount, withdrawn] = await Promise.all([
        publicClient.readContract({
          abi: AIRDROP_ABI,
          address: AIRDROP_ADDRESS,
          functionName: "getAirdropAmount",
          args: [address],
        }),
        publicClient.readContract({
            abi: AIRDROP_ABI,
            address: AIRDROP_ADDRESS,
            functionName: "getNextClaim",
            args: [address],
          }),
          publicClient.readContract({
            abi: AIRDROP_ABI,
            address: AIRDROP_ADDRESS,
            functionName: "getTotalAmount",
            args: [address],
          }),
          publicClient.readContract({
            abi: AIRDROP_ABI,
            address: AIRDROP_ADDRESS,
            functionName: "getWithdrawn",
            args: [address],
          }),
      ]);

      setAirdrop({
        airdropAmount: formatEther(airdropAmount || "0"),
        nextClaim: nextClaim || "0",
        totalAmount: formatEther(totalAmount || "0"),
        withdrawn: formatEther(withdrawn || "0"),
      });


    //} catch (err) {}
    
  };

  useEffect(() => {
    isConnected && getAirdrop(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [address, isConnected]);

  return (
    <AirdropContext.Provider value={{ ...state, getAirdrop }}>
      {children}
    </AirdropContext.Provider>
  );
};
