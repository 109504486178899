import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useAccount } from "wagmi";
import { Button } from "@/src/components";
import { ConnectButton } from "@/src/components/connectButton";
import { useBalances } from "@/src/hooks/useBalances";
import { useStake } from "@/src/hooks/useStake";
import { toast } from "react-hot-toast";
import { shortenAddress } from "@/src/lib/address";
import { LiquidityButton } from "@/src/views/home";
import { activeChain } from "@/src/configs/chainConfig";
import { useVotes } from "@/src/hooks/useVotes";

export const VoteCard = () => {
  const { chain } = useAccount();
  const { onVote } = useStake();
  const { address, isConnected } = useAccount();
  const { eligibleVotes, vote_enabled, supplyAmount, deadline } = useVotes();
  const submitButtonRef = useRef(null);

  const [{ isCorrectChain }, setState] = useState({
    isCorrectChain: true,
  });

  useEffect(() => {
    if (isConnected)
      setState({
        isCorrectChain: chain?.id === Number(activeChain.chainId),
      });
    
  }, [chain, isConnected]);

  const voteYes = async (event) => {
    event.preventDefault();
      return toast.promise(onVote(1), {
        loading: "Transaction is in progress",
        success: "Successfully voted Yes",
        error: (data) => data,
      });
  };
  const voteNo = async (event) => {
    event.preventDefault();
      return toast.promise(onVote(0), {
        loading: "Transaction is in progress",
        success: "Successfully voted No",
        error: (data) => data,
      });
  };
  return (
    <div>
    <div className="md:border-2 py-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8 text-foreground/50">
      <div className="py-1">
        <b>FRB Supply Increase Vote</b>
      </div>
      {vote_enabled &&
      <h2>Proposed Amount: {parseFloat(supplyAmount).toFixed(0)}</h2>}
      
      <div className="flex justify-center py-2 text-foreground/50">
           <p><b>Eligible votes:</b> {parseInt(eligibleVotes)}</p>
           
        </div>
        <div className="flex justify-center py-2 text-foreground/50">
          <p><b>Voting:</b> {(vote_enabled && Date.now()/1000 < (parseInt(deadline))) && "Open" || "Closed"}</p>
        </div>
        <div className="mt-10">
          {!isConnected && (
            <div className="flex justify-center">
              <ConnectButton />
            </div>
          )}
      </div>
      <form onSubmit={voteYes}>
          <div className="flex justify-center py-2 text-foreground/50">
          {isConnected && <Button type="submit" value="1" disabled={!isCorrectChain ||eligibleVotes==0 || vote_enabled==false} >Yes</Button>}
          </div>
          </form> 
      <form onSubmit={voteNo}>
        <div className="flex justify-center py-2 text-foreground/50">
          {isConnected && <Button type="submit" value="0" disabled={!isCorrectChain || eligibleVotes==0 || vote_enabled==false} >No</Button>}
          </div>
      </form>
      
    </div>
    </div>
  );
};
