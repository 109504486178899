import { Toaster } from "react-hot-toast";
import { WagmiProvider } from "wagmi";
import { Rewards } from "./pages/rewards";
import { Farms } from "./pages/farms";
import { Charge } from "./pages/charge";
import { Vote } from "./pages/vote";
import { wagmiConfig } from "@/src/configs/web3Modal";
import { AirdropProvider, BalanceProvider, RebaseProvider } from "@/src/context";
import { MetricsProvider } from "@/src/context";
import {  BrowserRouter as Router,  Routes,  Route} from "react-router-dom";
import "@/src/configs/web3Modal";
import { Dashboard } from "./pages/dashboard";
import { Metrics } from "./views/home";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { VoteProvider } from "./context/voteProvider";
import { DaoVoteProvider } from "@/src/context";
import { DaoVote } from "./pages/daovote";

const queryClient = new QueryClient()

function App() {
  return (
    <WagmiProvider config={wagmiConfig}>
       <QueryClientProvider client={queryClient}>
      <BalanceProvider>
        <RebaseProvider>
        <MetricsProvider>
          <VoteProvider>
            <DaoVoteProvider>
              <AirdropProvider>
        <Router>
          <Routes>
          <Route path="/" element={<Farms />} />
          <Route path="Farms" element={<Farms />} />
          <Route path="Rewards" element={<Rewards />} />
          <Route path="charge" element={<Charge />} />
          <Route path="Vote" element={<Vote />} />
          <Route path="Daovote" element={<DaoVote />} />
          <Route path="Metrics" element={<Dashboard />} />
          </Routes>
        </Router>
        <Toaster position="top-center" />
        </AirdropProvider>
        </DaoVoteProvider>
        </VoteProvider>
        </MetricsProvider>
        </RebaseProvider>
      </BalanceProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
