import { createContext, useEffect, useState } from "react";
import { formatEther, formatUnits } from "viem";
import { useAccount, usePublicClient } from "wagmi";
import {
  DAO_CONTRACT_ADDRESS,
  ERC20_ABI,
  ERC20_CONTRACT_ADDRESS,
  LP_CONTRACT_ADDRESS,
  NFT_ABI,
  NFT_CONTRACT_ADDRESS,
} from "../lib/constants";

// initial state
const defaultValues = {
  supply: 0,
  liquidity:0,
  burned:0,
  nftSupply:0,
  getMetrics: () => {},
};
// context
export const MetricsContext = createContext(defaultValues);

export const MetricsProvider = ({ children }) => {
  const publicClient = usePublicClient();
  const { address, isConnected } = useAccount();

  const [state, setMetrics] = useState({
    supply: 0,
    liquidity:0,
    burned:0,
    nftSupply:0,
  });
 
  const getMetrics = async (address) => {
    //try {
      const [supply, liquidity, burned, nftSupply] = await Promise.all([
        publicClient.readContract({
          abi: ERC20_ABI,
          address: ERC20_CONTRACT_ADDRESS,
          functionName: "totalSupply",
          args: [],
        }),
        publicClient.readContract({
          abi: ERC20_ABI,
          address: ERC20_CONTRACT_ADDRESS,
          functionName: "balanceOf",
          args: [LP_CONTRACT_ADDRESS],
        }),
        publicClient.readContract({
          abi: ERC20_ABI,
          address: ERC20_CONTRACT_ADDRESS,
          functionName: "balanceOf",
          args: ["0x000000000000000000000000000000000000dEaD"],
        }),
        publicClient.readContract({
          abi: NFT_ABI,
          address: NFT_CONTRACT_ADDRESS,
          functionName: "totalSupply",
          args: [],
        }),
      ]);

      setMetrics({
        supply: formatEther(supply || "0"),
        liquidity: formatEther(liquidity || "0"),
        burned: formatEther(burned || "0"),
        nftSupply: nftSupply || "0",
      });


    //} catch (err) {}
    
  };

  useEffect(() => {
    isConnected && getMetrics(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [address, isConnected]);

  return (
    <MetricsContext.Provider value={{ ...state, getMetrics }}>
      {children}
    </MetricsContext.Provider>
  );
};
