// songbird configurations
export const activeChain = {
  chainId: "0x72", // must be hexadecimal for chainId : 0x10 = 16
  chainName: "Flare Coston 2 Network",
  nativeCurrency: {
    symbol: "C2FLR",
    decimals: 18,
  },
  blockExplorerUrls: ["https://coston2-explorer.flare.network"],
  rpcUrls: ["https://coston2.enosys.global/ext/C/rpc"],
};
