import { BankIcon, Query } from "@/src/icons";
import { Card, Tooltip } from "@/src/components";
import { useBalances } from "@/src/hooks/useBalances";

export const Balance = () => {
  const { frbStakedBalance, wFlrBalance, userLpBalance } = useBalances();
  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
        <span className="flex items-center justify-between">
          <span className="flex items-center gap-2">
            
            <h2 className="text-2xl text-foreground font-semibold">Staked LP Balance</h2>
          </span>

          <div>
            
          </div>
        </span>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(frbStakedBalance).toFixed(2)} FRB / {parseFloat(wFlrBalance).toFixed(2)} SFLR
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Staked Liquidity Position</h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(userLpBalance/10000).toFixed(4)} %
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Farm Percentage</h2>
        </div>
       {/* <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseInt(frbBalance) < 1000 && parseFloat(frbBalance).toFixed(2)}
          {(parseInt(frbBalance) > 1000 && parseInt(frbBalance) < 1000000) && <> {parseFloat(frbBalance/1000).toFixed(2)} K</>}
          {(parseInt(frbBalance) > 1000000) && <>{parseFloat(frbBalance/1000000).toFixed(2)} M</>}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">FRB</h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(charged).toFixed(0)}/{parseFloat(nftBalance).toFixed(0)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Charged NFTs</h2>
        </div>
      */}
      </div>
    </Card>
    
  );
};
