import { BankIcon, Query } from "@/src/icons";
import { Card, Tooltip } from "@/src/components";
import { useBalances } from "@/src/hooks/useBalances";
import { Timer } from "@/src/components/deadlineTimer";

export const RechargeBalance = () => {
  const { nftBalance, unCharged, charged, chargeAmount, bronzeAmount, nextExpire, pendingRewards } = useBalances();
  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
        <span className="flex items-center justify-between">
          <span className="flex items-center gap-2">
            
            <h2 className="text-2xl text-foreground font-semibold">NFT Overview</h2>
          </span>
          <div> 
            
          </div>
        </span>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(charged).toFixed(0)}/{parseFloat(nftBalance).toFixed(0)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Charged Flare Reborn NFTs</h2>
        </div>
        {nextExpire > 0 &&
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseInt(nextExpire) > 0 ? <Timer time={parseInt(nextExpire)}/> : "00:00:00:00"}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Next NFT Expiration</h2>
        </div>
        
        }
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(pendingRewards).toFixed(0)} FRB
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Pending Rewards </h2>
        </div>
      </div>
    </Card>
    
    
  );
};
