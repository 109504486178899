import { BankIcon, Query } from "@/src/icons";
import { Card, Button } from "@/src/components";
import { useBalances } from "@/src/hooks/useBalances";
import { useStake } from "@/src/hooks/useStake";
import { useAccount } from "wagmi";
import { toast } from "react-hot-toast";
import { useDaoVotes } from "@/src/hooks/useVotes";
import { useEffect, useRef, useState } from "react";

export const DaoButtons = () => {
  const { yes, no, vote_enabled, deadline } = useDaoVotes();
  const { uncharged,charged } = useBalances();
  const {  onExecuteVote, onCancelDaoVote } = useStake();
  const { isConnected } = useAccount();
  const [isValid, setValid] = useState(false);
  const [isValid2, setValid2] = useState(false);

  const onIncrease = async () => {
    if (!isConnected) return;

    toast.promise(onExecuteVote(), {
      loading: "Transaction is in progress",
      success: "Successfully executed proposed change",
      error: (data) => data,
    });
  }
  const onCancel = async () => {
    if (!isConnected) return;

    toast.promise(onCancelDaoVote(), {
      loading: "Transaction is in progress",
      success: "Successfully canceled proposal",
      error: (data) => data,
    });
  }
  const validate = () => {
    if (parseInt(no) >= parseInt(yes)){
      return false;
    }
    if (vote_enabled==false){
      return false;
    }
    if ((parseInt(uncharged)+parseInt(charged)) == 0){
      return false;
    }
    if (Date.now()/1000 < (deadline)){
      return false;
    }else{return true;}
  };
  const validate2 = () => {
    if ((parseInt(yes) > parseInt(no) && (Date.now()/1000 > (parseInt(deadline)+3600))) || Date.now()/1000 < (deadline)){
      return false;
    }
    if (vote_enabled==false){
      return false;
    }
    if ((parseInt(uncharged)+parseInt(charged)) == 0){
      return false;
    }
    if (Date.now()/1000 < (deadline)){
      return false;
    }else{return true;}
  };

  useEffect(() => {
    const isValid = validate();
    const isValid2 = validate2();
    setValid(isValid);
    setValid2(isValid2);
  }, [vote_enabled, uncharged, deadline, yes, no]);

  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
      <div className="flex items-center h-full">
        <Button onClick={onIncrease} className="" disabled={!isValid}>
          <h2 className="md:text-xl text-xl text-foreground">Execute Proposal</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
        <Button onClick={onCancel} className="" disabled={!isValid2}>
          <h2 className="md:text-xl text-xl text-foreground">Cancel Proposal</h2>
        </Button>
        {}
      </div>
      </div>
    </Card>
    
  );
};
