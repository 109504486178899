import { UserLayout } from "@/src/layouts/UserLayout";
import { Card } from "@/src/components";
import { Balance, Boosts, FarmBalance, HarvestButton, PendingRewards, HarvestInfo, Stake, XenosFarm } from "@/src/views/home";
import { FileAdd } from "@/src/icons";
import { RebaseProvider } from "@/src/context";

export const Farms = () => {
  return (
    <UserLayout>
      <div className="grid grid-cols-12 gap-4 max-w-[98rem] w-full h-full m-auto p-5">
      <div className="col-span-12 md:col-span-8">
          <Card className="md:min-h-[calc(100vh-130px)] h-full">
          <div className="col-span-12 md:col-span-4 h-full flex flex-col gap-4 mx-auto max-w-xl">
          <Stake />
          </div>
          </Card>
        </div>
          <div className="col-span-12 md:col-span-4 h-full flex flex-col gap-4">
          <Balance />
          <Boosts />
          <PendingRewards />
          <HarvestButton />
          <HarvestInfo />
        </div>
      </div>
     
    </UserLayout>
  );
};
