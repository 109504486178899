import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useAccount } from "wagmi";
import { Button } from "@/src/components";
import { ConnectButton } from "@/src/components/connectButton";
import { useBalances } from "@/src/hooks/useBalances";
import { useStake } from "@/src/hooks/useStake";
import { toast } from "react-hot-toast";
import { shortenAddress } from "@/src/lib/address";
import { LiquidityButton } from "@/src/views/home";
import { activeChain } from "@/src/configs/chainConfig";
import { useDaoVotes } from "@/src/hooks/useVotes";

export const DaoVoteCard = () => {
  const { chain } = useAccount();
  const { onVote, onProposeChange, onExecuteVote, onCancelDaoVote, onDaoVote } = useStake();
  const { uncharged,charged, frbBalance } = useBalances();
  const { address, isConnected } = useAccount();
  const { eligibleVotes, vote_enabled, functionCalled, deadline, var1, var2, var3, voteAmount } = useDaoVotes();
  const submitButtonRef = useRef(null);

  const [{ isCorrectChain }, setState] = useState({
    isCorrectChain: true,
  });
  const [{ _var1, _var2, _var3 }, setInput] = useState({
    _var1: 0,
    _var2: 0,
    _var3: "",
  });

  const onInputVar1 = (event) => {
    const value = event.target.value;
    setInput(prevState => ({
      ...prevState,
      _var1: value
    }));
  };
  const onInputVar2 = (event) => {
    const value = event.target.value;
    setInput(prevState => ({
      ...prevState,
      _var2: value
    }));
  };
  const onInputVar3 = (event) => {
    const value = event.target.value;
    setInput(prevState => ({
      ...prevState,
      _var3: value
    })); 
  }; 

  const onChange = async (e) => {
    if (!isConnected) return;

    console.log(_var1);
    console.log(_var2);

    toast.promise(onProposeChange(e.currentTarget.id, _var1 , _var2, _var3), {
      loading: "Transaction is in progress",
      success: "Successfully proposed change",
      error: (data) => data,
    });
  }

  useEffect(() => {
    if (isConnected)
      setState({
        isCorrectChain: chain?.id === Number(activeChain.chainId),
      });
    
  }, [chain, isConnected]);

  const voteYes = async (event) => {
    event.preventDefault();
      return toast.promise(onDaoVote(1), {
        loading: "Transaction is in progress",
        success: "Successfully voted Yes",
        error: (data) => data,
      });
  };
  const voteNo = async (event) => {
    event.preventDefault();
      return toast.promise(onDaoVote(0), {
        loading: "Transaction is in progress",
        success: "Successfully voted No",
        error: (data) => data,
      });
  };
  return (
    <div>
    <div className="md:border-2 py-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8 text-foreground/50">
      {vote_enabled &&
      <>
      <div className="py-1">
        <b>FUNCTION CALLED: {functionCalled}</b>
      </div>
      {  var1 != 0 && <h2>Parameter 1: {parseFloat(var1).toFixed(0)}</h2>}
      {  var2 != 0 && <h2>Parameter 2: {parseFloat(var2).toFixed(0)}</h2>}
      {  var3 != "0x0000000000000000000000000000000000000000" && <h2>Parameter 3: {var3}</h2> }
      </>
      }
      
      <div className="flex justify-center py-2 text-foreground/50">
           <p><b>Eligible votes:</b> {parseInt(eligibleVotes)}</p>
           
        </div>
        <div className="flex justify-center py-2 text-foreground/50">
          <p><b>Voting:</b> {(vote_enabled && Date.now()/1000 < (parseInt(deadline))) && "Open" || "Closed"}</p>
        </div>
        <div className="mt-10">
          {!isConnected && (
            <div className="flex justify-center">
              <ConnectButton />
            </div>
          )}
      </div>
      <form onSubmit={voteYes}>
          <div className="flex justify-center py-2 text-foreground/50">
          {isConnected && <Button type="submit" value="1" disabled={!isCorrectChain ||eligibleVotes==0 || vote_enabled==false } >Yes</Button>}
          </div>
          </form> 
      <form onSubmit={voteNo}>
        <div className="flex justify-center py-2 text-foreground/50">
          {isConnected && <Button type="submit" value="0" disabled={!isCorrectChain || eligibleVotes==0 || vote_enabled==false } >No</Button>}
          </div>
      </form>
    </div>
    <div className="md:border-2 py-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8 text-foreground/50">
    <div className="py-1">
        <h2>{parseFloat(voteAmount).toFixed(0)} FRB and minimum 1 NFT is required to propose a change. This can be refunded when voted by the community. Refund is doubled to incentivize proposals
        </h2>
      </div>
    <div className="flex items-center h-full">
    <div className="py-1">
    <b>Pause Farm</b>
      </div>
        </div>
        <div className="flex items-center h-full">
        <Button id="pause" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <b>Unpause Farm</b>
      </div>
        </div>
        <div className="flex items-center h-full">
        <Button id="unpause" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <b>Transfer FRB</b>
      </div>
        </div>
        <div className="py-1">
            <input
              onChange={onInputVar1}
              placeholder="Amount of FRB (WEI format)"
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />
            </div>
            <div className="py-1">
        <input
              onChange={onInputVar3}
              placeholder="Contract Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="transferFrb" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1">  <b>Increase FRB Supply</b></div>
      </div>
        </div>
        <div className="py-1">
        <input
              onChange={onInputVar1}
              placeholder="Amount of FRB (WEI format)"
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="increaseSupply" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
     <b>Withdraw FRB from Farm</b>
      </div>
        </div>
        <div className="flex items-center h-full">
        <Button id="withdrawFarm" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1">  <b>Set FRB Amount each Harvest</b></div>
      </div>
        </div>
        <div className="py-1">
        <input
              onChange={onInputVar1}
              placeholder="Amount of FRB (WEI format)"
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="setHarvestAmount" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"><b>Set Harvest Frequency</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar1}
              placeholder="Number of seconds between each harvest"
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="setHarvestFrequency" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"> <b>Set minimum staked FRB to earn Harvest Points</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar1}
              placeholder="Amount of FRB (WEI format)"
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="setHarvestMinimum" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"> <b>Set Amount of Harvest Points earned each Harvest</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar1}
              placeholder="Amount of Harvest Points (WEI format)"
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="setHarvestPoints" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"> <b>Set Charge Duration on NFTs</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar1}
              placeholder="NFT Charge Duration in seconds"
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="setChargeDuration" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"> <b>Change Harvest Rewards Contract (upgrade)</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar3}
              placeholder="Contract Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="setHarvestRewardsContract" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"> <b>Add NFT collection to staking boost</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar3}
              value={_var3}
              placeholder="Contract Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
            <div className="py-1">
        <input
              onChange={onInputVar1}
              value={_var1}
              placeholder="Boost amount: percentage * 10000. 15% = 150000"
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />
            </div>
            <div className="py-1">
        <input
              onChange={onInputVar2}
              value={_var2}
              placeholder="Maximum amount of NFTs to take into account for boost"
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="addRewardNFT" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"><b>Remove NFT collection from staking boost</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar3}
              placeholder="Contract Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="removeRewardsNFT" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"><b>Set FRB amount to mint NFT</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar1}
              placeholder="Amount of FRB (WEI format)"
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="setRebornAmount" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"> <b>Set FRB amount to charge NFT</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar1}
              placeholder="Amount of FRB (WEI format)"
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="setChargeReborn" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"><b>Add NFT that is mintable using Harvest Points and FRB</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar3}
              placeholder="Contract Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
            <div className="py-1">
        <input
              onChange={onInputVar1}
              placeholder="Amount of Harvest Points to mint NFT (WEI format)"
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="addRewardNft" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"> <b>Remove NFT from being mintable using HarvestPoints and FRB</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar3}
              placeholder="Contract Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="removeRewardNft" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"> <b>Transfer LP tokens locked in this contract to multi-signature safe (used when migrating to new DEX)</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar3}
              placeholder="Contract Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="transferLpToSafe" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"><b>Refund proposer of previous voting round</b></div>
      </div>
      </div>
        <div className="flex items-center h-full">
        <Button id="refundVote" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"> <b>Set Amount required to propose a vote</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar1}
              placeholder="Amount of FRB (WEI format)"
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="setVoteAmount" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"> <b>Change owner of Farm & Reward Contracts (when migrating to new DAO contract)</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar3}
              placeholder="Contract Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="transferContractsOwnership" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"><b>Change owner of NFT Contract (when migrating to new DAO contract)</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar3}
              placeholder="Contract Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="nftTransferOwnership" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"><b>Change LP token for farm (When migrating liquidity to new (V3) DEX)</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar3}
              placeholder="Contract Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="setLpToken"onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
     </div>
      
    </div>
    
  );
};
