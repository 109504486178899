import canyDaoABI from "@/src/assets/FlareCanaryFarm.json";
import FlareReborn from "@/src/assets/FlareReborn.json";
import nftABI from "@/src/assets/FlareHarvestNft.json";
import rewardsABI from "@/src/assets/FlareRewards.json";
import farm from "@/src/assets/FlareFarm.json";
import vote from "@/src/assets/RebornVote.json";
import daoReborn from "@/src/assets/DaoReborn.json";
import daoVote from "@/src/assets/DaoVote.json";
import airdrop from "@/src/assets/airdrop.json";

export const CANARY_DAO_ABI = canyDaoABI;
export const ERC20_ABI = FlareReborn;
export const NFT_ABI = nftABI;
export const LP_ABI = FlareReborn;
export const REWARD_ABI = rewardsABI;
export const FARM_ABI = farm;
export const VOTE_ABI = vote;
export const REBORN_DAO_ABI = daoReborn;
export const DAO_VOTE_ABI = daoVote;
export const AIRDROP_ABI = airdrop;

export const DAO_CONTRACT_ADDRESS =
  "0x12c5F4e0986574AF101a03352176AFBeBE6C320F";
export const ERC20_CONTRACT_ADDRESS =
  "0x049BF0ffA32D2181E66B03978Ae270231Dce51B5";
export const NFT_CONTRACT_ADDRESS =
"0xFb823a3C37601F7Ffa186fDB25A5879897136B39";
export const LP_CONTRACT_ADDRESS =
"0x2F31DE2A224297E5649f552b97b47738eB12e838";
export const REWARD_CONTRACT_ADDRESS =
"0x2687Be0e90877c37E46A5992938D76201a1121ec";
export const FARM_ADDRESS =
"0x12c5F4e0986574AF101a03352176AFBeBE6C320F";
export const VOTE_ADDRESS = 
"0x95e818453d132eC6916bF030677b093F7fcC5780";
export const DAO_REBORN_ADDRESS =
"0x79Ec180B24f7B665D66091317AB87ADCd44C34B3";
export const DAO_VOTE_ADDRESS = 
"0x6FD91D67b94eF1d3a60DFF0d3194599106F0D2C8";
export const AIRDROP_ADDRESS = 
"0x0EDc562242bdE0Da5e1c7753431572FB1fEF6302";

