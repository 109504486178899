import { createContext, useEffect, useState } from "react";
import { formatEther, formatUnits } from "viem";
import { useAccount, usePublicClient } from "wagmi";
import {
  CANARY_DAO_ABI,
  DAO_CONTRACT_ADDRESS,
  ERC20_ABI,
  ERC20_CONTRACT_ADDRESS,
  NFT_ABI,
  NFT_CONTRACT_ADDRESS,
  LP_CONTRACT_ADDRESS,
  REWARD_ABI,
  REWARD_CONTRACT_ADDRESS,
} from "../lib/constants";

// initial state
const defaultValues = {
  balance: 0,
  stakeBalance: 0,
  frbBalance:0,
  nftBalance: 0,
  nftBoost: 0,
  rewardPoints:0,
  rewardRequirement:0,
  harvestMinimum:0,
  harvestRate:0,
  unCharged:0,
  charged:0,
  chargeReborn:0,
  rewardReborn:0,
  nextExpire:0,
  pendingRewards: 0,
  pendingNetRewards:0,
  canaryHarvestAmount:0,
  frbStakedBalance: 0,
  wFlrBalance:0,
  userLpBalance:0,
  getBalances: () => {},
};
// context
export const BalanceContext = createContext(defaultValues);

export const BalanceProvider = ({ children }) => {
  const publicClient = usePublicClient();
  const { address, isConnected } = useAccount();

  const [state, setBalances] = useState({
    balance: 0,
    stakeBalance: 0,
    frbBalance:0,
    nftBalance: 0,
    nftBoost: 0,
    rewardPoints:0,
    rewardRequirement:0,
    harvestMinimum:0,
    harvestRate:0,
    unCharged:0,
    charged:0,
    chargeReborn:0,
    rewardReborn:0,
    nextExpire:0,
    pendingRewards: 0,
    pendingNetRewards:0,
    canaryHarvestAmount:0,
    frbStakedBalance: 0,
    wFlrBalance:0,
    userLpBalance:0,
  });

  const getBalances = async (address) => {
      const [balance, stakeBalance, frbBalance, nftBalance, nftBoost, rewardPoints, rewardRequirement, harvestMinimum, harvestRate, rewardReborn, unCharged, charged, chargeReborn, nextExpire, pendingRewards, pendingNetRewards, canaryHarvestAmount,frbStakedBalance,wFlrBalance, userLpBalance] = await Promise.all([
        publicClient.readContract({
          abi: ERC20_ABI,
          address: LP_CONTRACT_ADDRESS,
          functionName: "balanceOf",
          args: [address],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "getBalance",
          args: [address],
        }),
        publicClient.readContract({
          abi: ERC20_ABI,
          address: ERC20_CONTRACT_ADDRESS,
          functionName: "balanceOf",
          args: [address],
        }),
        publicClient.readContract({
          abi: NFT_ABI,
          address: NFT_CONTRACT_ADDRESS,
          functionName: "balanceOf",
          args: [address],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "calculateNftBoost",
          args: [address],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "getHarvestPoints",
          args: [address],
        }),
        publicClient.readContract({
          abi: REWARD_ABI,
          address: REWARD_CONTRACT_ADDRESS,
          functionName: "rewardNfts",
          args: [NFT_CONTRACT_ADDRESS], 
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "harvestMinimum",
          args: [],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "harvestPoints",
          args: [],
        }),
        publicClient.readContract({
          abi: REWARD_ABI,
          address: REWARD_CONTRACT_ADDRESS,
          functionName: "rebornAmount",
          args: [],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "getUnChargedNfts",
          args: [address],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "getChargedNfts",
          args: [address],
        }),
        publicClient.readContract({
          abi: REWARD_ABI,
          address: REWARD_CONTRACT_ADDRESS,
          functionName: "chargeReborn",
          args: [],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "getNextChargeExpiration",
          args: [address],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "getPendingRewards",
          args: [address],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "getNetPendingRewards",
          args: [address],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "harvestAmount",
          args: [],
        }),
        
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "userFrbBalance",
          args: [address],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "userWflrBalance",
          args: [address],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "userLpBalance",
          args: [address],
        }),
      ]);
      setBalances({
        balance: formatEther(balance || "0"),
        stakeBalance: formatEther(stakeBalance || "0"),
        frbBalance: formatEther(frbBalance || "0"),
        nftBalance: nftBalance || "0",
        nftBoost: parseInt(nftBoost) || "0",
        rewardPoints: formatEther(rewardPoints) || "0",
        rewardRequirement: formatEther(rewardRequirement) || "0",
        harvestMinimum: formatEther(harvestMinimum) || "0",
        harvestRate: formatEther(harvestRate) || "0",
        rewardReborn: formatEther(rewardReborn) || "0",
        unCharged: unCharged || "0",
        charged: charged || "0",
        chargeReborn: formatEther(chargeReborn) || "0",
        nextExpire: formatUnits(nextExpire,0),
        pendingRewards: formatEther(pendingRewards || "0"),
        pendingNetRewards: formatEther(pendingNetRewards || "0"),
        canaryHarvestAmount: formatEther(canaryHarvestAmount || "0"),
        frbStakedBalance: formatEther(frbStakedBalance || "0"),
        wFlrBalance: formatEther(wFlrBalance || "0"),
        userLpBalance: formatUnits(userLpBalance,0),
      });
    
  };

  useEffect(() => {
    isConnected && getBalances(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isConnected]);

  return (
    <BalanceContext.Provider value={{ ...state, getBalances }}>
      {children}
    </BalanceContext.Provider>
  );
};
