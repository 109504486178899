import { BankIcon, Query } from "@/src/icons";
import { Card, Tooltip } from "@/src/components";
import { useBalances } from "@/src/hooks/useBalances";

export const Tvl = () => {
  const { stakeBalance, crbPrice } = useBalances();
  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(crbPrice).toFixed(6)} $
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">FRB Price</h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
            {parseFloat(stakeBalance*crbPrice).toFixed(2)} $
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">
            Staked Value
          </h2>
        </div>
        
      </div>
    </Card>
    
  );
};
